// AKA PINs
export const tribalIds = [
  "health",
  "5osce24",
  "cas524so",
  "temp",
  "1234",
  "5678",
  "4321",
  "8765",
  "AC031694",
  "SB111191",
  "PK120669",
  "AC071292",
  "mgraham@miccosukeetribe.com",
];

/*
TEST USERS.

Ana Concepcion 03/16/1994
PIN: AC031694

Sorab Boga 11/11/1991
PIN: SB111191

Cassandra Osceola 05/20/1962
PIN: cas524so

Patricia Kelly 12/06/1969
PIN: PK120669

Alyssa Crymes 07/12/1992
PIN: AC071292

Curtis Osceola 
PIN: 5osce24
*/
